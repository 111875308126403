export const labelMap = {
  "name": "Name",
  "whichGills": "Gills",
  "capShape": "Cap",
  "hymeniumType": "Hymenium",
  "stipeCharacter": "Stipe",
  "ecologicalType": "Ecology",
  "sporePrintColor": "Spores",
  "howEdible": "Edibility"
};
